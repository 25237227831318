@import "./variables.scss";

.navbar {
  font-family: BrixSlab-Bold;
}

h1 {
  font-size: 60px;
  font-family: BrixSlab-Bold;
  line-height: 70px;
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 45px;
  }
}

h2 {
  font-size: 30px;
  font-family: BrixSlab-Bold;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
}

h3 {
  font-size: 20px;
  font-family: BrixSlab-Bold;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
}

.headline-underline {
  text-transform: uppercase;
  margin-bottom: 1.4em;
  span {
    position: relative;
    display: inline-block;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      bottom: -0.5em;
      left: 0;
      border-bottom: 4px solid $black;
    }
  }
}

.bold-text-1 {
  font-size: 25px;
  font-family: BrixSlab-Bold;
  line-height: 29px;
}

.bold-text-2 {
  font-size: 18px;
  font-family: BrixSlab-Bold;
  line-height: 22px;
}

a {
  font-size: 18px;
  font-family: BrixSlab-Bold;
  color: #ffffff;
  &:hover {
    color: #eeeeee;
  }
}

p {
  font-family: NunitoSans-Regular;
  font-size: 16px;
  line-height: 1.3;
}

.sb-red-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: BrixSlab-Bold;
    color: $primary;
  }
}
.sb-white-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    font-family: BrixSlab-Bold;
    color: $white;
  }

  .headline-underline {
    span::after {
      border-color: $primary;
    }
  }
}

.card {
  border-radius: 0;
}

.site {
  min-height: 100vh;

  // color: $white;
  font-family: NunitoSans-Regular;
  font-size: 16px;
}

.re-button {
  display: inline-flex;
  border: 4px solid $black;
  font-family: BrixSlab-Bold;
  font-size: 20px;
  padding: 0.4em 1.5em;
  height: 50px;
  align-items: center;
  cursor: pointer;
  background: $white;

  img {
    height: 30px;
    position: relative;
    top: -1px;
  }
}

//sections

.black-section {
  background: $black;
  color: $white;
}

.red-section {
  background: $primary;
  color: white;
}

.white-section {
  background: $white;
  color: $primary;
}

.is-red-image {
  > img {
    width: 100%;
    object-fit: cover;
    filter: brightness(0.5) sepia(1) saturate(3000%) contrast(0.5);
  }
}

.re-uppercase {
  text-transform: uppercase;
}

.re-pointer {
  cursor: pointer;
}

label {
  font-family: BrixSlab-Bold;
}

select {
  font-family: BrixSlab-Bold;
}

.re-top-space-holder {
  height: 300px;
}

.height-100 {
  height: 100%;
}

.site-title-container {
  padding-top: 8rem;
  padding-bottom: 8rem;
  padding-left: 1rem;
  padding-right: 1rem;
  @media screen and (max-width: $breakpoint-mobile) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

}

.site-title {
  // font-size: 4rem;
  // line-height: 4rem;
  padding-top: 200px;
  color: white;
  @media screen and (max-width: $breakpoint-mobile) {
    padding-top: 80px;
  }
}

.bounce {
  -moz-animation: bounce 4s infinite;
  -webkit-animation: bounce 4s infinite;
  animation: bounce 4s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  60% {
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.padding-6-resp {
  padding: 1rem;
  @media screen and (min-width: $breakpoint-mobile) {
    padding: 3.25rem;
  }
}

  
.red-link {
  color: $primary;
}