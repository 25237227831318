$red: #eb5c37;
$red-light: rgba(235, 92, 55, 0.15);
$black: #38383a;
$grey: #707070;
$white: #ffffff;

// // Update Bulma's global variables
$primary: $red;
$family-sans-serif: "NunitoSans-Regular";

body {
  --ion-color-primary: #ffffff;
}

$breakpoint-mobile: 768px;
$breakpoint-tablet: 1024px;
$breakpoint-desktop: 1216px;
$breakpoint-fullhd: 1408px;
