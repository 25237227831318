@font-face {
  font-family: BrixSlab-Regular;
  src: url(assets/fonts/BrixSlab-Regular.otf);
}
@font-face {
  font-family: BrixSlab-Bold;
  font-weight: bold;
  src: url(assets/fonts/BrixSlab-Bold.otf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(assets/fonts/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Bold;
  font-weight: bold;
  src: url(assets/fonts/NunitoSans-Bold.ttf);
}

// Set your brand colors
@import "./styles/variables.scss";

// $grey-dark: $brown;
// $grey-light: $beige-light;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// // Update some of Bulma's component variables
// // $body-background-color: $beige-lighter;
// $body-background-color: #fff;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

// Import only what you need from Bulma
@import "node_modules/bulma/sass/utilities/_all";
@import "node_modules/bulma/sass/base/_all";
@import "node_modules/bulma/sass/elements/button";
@import "node_modules/bulma/sass/elements/container";
@import "node_modules/bulma/sass/elements/title";
@import "node_modules/bulma/sass/elements/image";
@import "node_modules/bulma/sass/elements/tag";
@import "node_modules/bulma/sass/form/_all";
@import "node_modules/bulma/sass/components/navbar";
@import "node_modules/bulma/sass/components/card";
@import "node_modules/bulma/sass/components/modal";
@import "node_modules/bulma/sass/layout/hero";
@import "node_modules/bulma/sass/layout/section";
@import "node_modules/bulma/sass/grid/columns";
@import "node_modules/bulma/sass/grid/tiles";
@import "node_modules/bulma/sass/helpers/_all";
// @import "node_modules/bulma/sass/utilities/mixins";
// @import "node_modules/bulma/sass/utilities/controls";
@import "node_modules/bulma/bulma.sass";
@import "./styles/general.scss";

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

@import "mapbox-gl/dist/mapbox-gl.css";
